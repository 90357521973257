import * as THREE from "three";

export class Eyes {
  constructor(scene, camera, renderer, raycaster) {
    this.scene = scene;
    this.camera = camera;
    this.raycaster = raycaster;
    this.renderer = renderer;
    this.cubes = [];
    this.init();
    this.addMouseTracking();
  }

  init() {
    const eyeGeometry = new THREE.SphereGeometry(2, 32, 32);
    const eyeMaterial = new THREE.MeshStandardMaterial({ color: 0xD3D3D3 }); // white color for the eyeball
    this.eyeball = new THREE.Mesh(eyeGeometry, eyeMaterial);
    this.eyeball.position.set(0, 4, 0);
    this.scene.add(this.eyeball);

    // Iris
    const irisGeometry = new THREE.CircleGeometry(0.5, 32); // smaller circle for the iris
    const irisMaterial = new THREE.MeshStandardMaterial({ color: 0x00FFD1 }); // blue color for the iris
    this.iris = new THREE.Mesh(irisGeometry, irisMaterial);
    this.iris.position.set(0, 0, 2); // adjust position to be on the surface of the eyeball
    // this.scene.add( this.iris);
    // Make iris a child of the eyeball
    this.eyeball.add(this.iris);

    const spotLight1 = new THREE.SpotLight(0xffffff); // white light
    spotLight1.position.set(5, 5, 5); // adjust position as needed
    spotLight1.angle = Math.PI / 4;
    spotLight1.penumbra = 0.1;
    spotLight1.decay = 2;
    spotLight1.intensity = 50;
    spotLight1.distance = 200;
    spotLight1.castShadow = true;
    spotLight1.target = this.eyeball; // assuming eyeball is your eye mesh
    this.scene.add(spotLight1);

  }


  addMouseTracking() {
    const onMouseMove = (event) => {
      const container = document.getElementById('d-container-2');
      const bounds = container.getBoundingClientRect();
  
      // Normalized mouse coordinates relative to d-container-2
      const mouse = new THREE.Vector2(
        ((event.clientX - bounds.left) / bounds.width) * 2 - 1,
        -((event.clientY - bounds.top) / bounds.height) * 2 + 1
      );
  
      // Define the maximum rotation angle (in radians)
      const maxRotation = Math.PI / 2; // Adjust this value as needed
  
      // Calculate the rotation angles based on the mouse position
      const rotationX = -mouse.y * maxRotation;
      const rotationY = mouse.x * maxRotation;
  
      // Apply the rotation to the eyeball
      if (this.eyeball) {
        this.eyeball.rotation.y = rotationY;
        this.eyeball.rotation.x = rotationX;
      }
    };
  
    this.renderer.domElement.addEventListener('mousemove', onMouseMove, false);
  }
  

  update() {
    // Update logic for the Rubik's Cube
  }

  render() {
    // Render logic for the Rubik's Cube
    // This might include rotating parts of the cube
  }
}
