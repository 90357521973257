export function positionTargetBelowWorkTitle() {
    const workTitle = document.querySelector(".work-title");
    const target = document.querySelector(".target");

    if (workTitle && target) {
      const workTitleBottom = workTitle.offsetTop + workTitle.offsetHeight;
      const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
      target.style.top = `${workTitleBottom + 5 * remInPixels}px`;
    } else {
      console.error("Elements not found");
    }
  }
