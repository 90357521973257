import projectDetails from "./projects.js";

export function initializeProjectEvents() {
  const projects = document.querySelectorAll(".project");

  projects.forEach((project, index) => {
    project.addEventListener("click", () => {
      updateContent(index);
      updateSelectedProject(project);
    });
  });

  if (projects.length > 0) {
    updateContent(0);
    updateSelectedProject(projects[0]);
  }
  setupSkillCategoryClicks();
  // Select and highlight the first skill category
  const firstSkillCategory = document.querySelector(".skills-column p");
  if (firstSkillCategory) {
    updateSkillsContent(0);
    firstSkillCategory.classList.add("selected");
    firstSkillCategory.classList.add("skill-category-active");
  }
}

function updateContent(projectIndex) {
  const project = projectDetails[projectIndex];
  if (!project) return;

  const titleSpan = `${project.title}&nbsp;<span class="${
    project.title.includes("Practice Project") ? "project-title-practice" : "project-title-regular"
  }">(${project.subTitle})</span>`;
  document.querySelector(".project-top-container p").innerHTML = titleSpan;
  document.querySelector(".project-top-container p:nth-child(2)").textContent = project.startDate;
  document.querySelector(".project-top-icons").textContent = "";
  document.querySelector(".project-picture").textContent = "";

  const learningList = document.querySelector(".learning-list");
  learningList.innerHTML = "<p>What I have mastered:</p>";
  project.learning.forEach((skill) => {
    learningList.innerHTML += `<p>${skill}</p>`;
  });

  if (project.topIcons.length > 0) {
    const projectTopIcons = document.querySelector(".project-top-icons");
    project.topIcons.forEach((skill, index) => {
      if (project.topLink[index]) {
        projectTopIcons.innerHTML += `<img src=${skill} onclick="window.open('${project.topLink[index]}', '_blank')" />`;
      } else {
        projectTopIcons.innerHTML += `<img src=${skill} />`;
      }
    });
  }

  if (project.projectPicture) {
    const projectPicture = document.querySelector(".project-picture");
    projectPicture.innerHTML += `<img src=${project.projectPicture} />`;
    projectPicture.style.opacity = 1;
    const projectPicturesTitle = document.querySelector(".project-pictures-title");
    projectPicturesTitle.innerHTML = "Project's Pictures:";
  } else {
    const projectPicture = document.querySelector(".project-picture");
    projectPicture.innerHTML += `<img src="../../../public/images/medTuto.png" />`;
    projectPicture.style.opacity = 0;
    const projectPicturesTitle = document.querySelector(".project-pictures-title");
    projectPicturesTitle.innerHTML = "";
  }
}
function updateSelectedProject(selectedProjectElement) {
  document.querySelectorAll(".projects-list .project").forEach((project) => {
    project.classList.remove("project-selected");
  });
  selectedProjectElement.classList.add("project-selected");
}

function setupSkillCategoryClicks() {
  const skillCategories = document.querySelectorAll(".skills-column p");
  skillCategories.forEach((category, index) => {
    category.addEventListener("click", function () {
      updateSkillsContent(index);
      skillCategories.forEach((cat) => cat.classList.remove("skill-category-active"));
      category.classList.add("skill-category-active");
    });
  });
}

function updateSkillsContent(index) {
  const skillContainers = document.querySelectorAll(".skill-container");
  skillContainers.forEach((container) => {
    container.style.display = "none";
  });

  const selectedSkillContainer = document.querySelector(`.skill-container-${index + 1}`);
  if (selectedSkillContainer) {
    selectedSkillContainer.style.display = "grid";
  }
}
