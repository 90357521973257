import * as THREE from "three";

export class RedFogEffect {
  constructor(scene) {
    this.scene = scene;
    this.multipler = 1.5;
    this.createRedFog();
    this.maxDeductions = 35;
    this.deductionCount = 0;
  }

  createRedFog() {
    this.redFog = new THREE.Fog(0x00FFD1, 10 * this.multipler + 6, 15 * this.multipler + 40);
    this.scene.fog = this.redFog;
  }

  updateFog() {
    this.deductionCount++; // Increment the counter

    if (this.deductionCount >= this.maxDeductions) {
      // Remove the fog from the scene after 10 deductions
      this.scene.fog = null;
      return true;
    }

    const reductionRate = -0.5; // Adjust this value for faster or slower reduction
    this.redFog.near -= reductionRate;
    this.redFog.far -= reductionRate;

    // Clamp values to ensure they don't go below a certain threshold
    this.redFog.near = Math.max(0, this.redFog.near);
    this.redFog.far = Math.max(0, this.redFog.far);

  }
}
