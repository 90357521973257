import * as THREE from "three";



export class Earth {
  vertexShader = `    varying vec2 vUv;
    
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`;
  fragmentShader = `     
  uniform sampler2D earthTexture;
  varying vec2 vUv;
  
  // Simple pseudo-random function
  float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }
  
  void main() {
    vec4 texel = texture2D(earthTexture, vUv);
  
    // Define a threshold for blue dominance
    float blueThreshold = 0.9;
  
    // Generate a random value based on the UV coordinates
    float noise = rand(vUv * 100.0); // Adjust multiplication factor for density
  //   && noise > 0.001
    // Check if the blue component is not the dominant color
    if (texel.b < max(texel.r, texel.g) && texel.b < blueThreshold) { // Adjust noise threshold for spacing
      // The pixel is not predominantly blue and passes the noise threshold
      gl_FragColor = vec4(0.,1.,0.82, 1.0); // Red color, fully opaque

      float maxIntensity = 0.4; // Maximum intensity threshold
      vec3 color = min(vec3(0.,1.,0.82), vec3(maxIntensity));
      gl_FragColor = vec4(color, texel.a);
    } else {
      // The pixel is predominantly blue or doesn't pass the noise threshold
      discard; // Discards the fragment, rendering it fully transparent
    }
  }`;


  constructor(scene, camera) {
    this.scene = scene;
    this.camera = camera;
    this.earthMesh = null;
    this.innerEarthMesh = null;
    this.backgroundMesh = null;

    this.init();
    this.addSpotlight(1, 0, 0, "east");
    this.addSpotlight(-1, 0, 0, "west");
    this.addSpotlight(0, 1, 0, "north");
    this.addSpotlight(0, -1, 0, "south");
  }

  createBackground() {
    const backgroundGeometry = new THREE.PlaneGeometry(200, 200);
    const loader = new THREE.TextureLoader();
    const backgroundMaterial = new THREE.MeshStandardMaterial({
      map: loader.load("./space.jpg"),
      side: THREE.DoubleSide,
    });

    this.backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
    this.backgroundMesh.rotation.x = this.camera.rotation.x;
    this.backgroundMesh.rotation.y = this.camera.rotation.y;
    this.backgroundMesh.rotation.z = this.camera.rotation.z;
    this.backgroundMesh.position.y -= 20;
    this.scene.add(this.backgroundMesh);
  }

  init() {
    const earthGeometry = new THREE.SphereGeometry(10, 32, 32);
    const loader = new THREE.TextureLoader();
    const earthMaterial = new THREE.MeshPhongMaterial({
      map: loader.load("./earth.jpg"),
    });

    this.earthMesh = new THREE.Mesh(earthGeometry, earthMaterial);
    this.earthMesh.material = this.createEarthShaderMaterial(loader);
    this.scene.add(this.earthMesh);

    this.initInnerEarth();
  }

  createEarthShaderMaterial(loader) {
    return new THREE.ShaderMaterial({
      uniforms: {
        earthTexture: { type: "t", value: loader.load("./earth.jpg") },
      },
      vertexShader: this.vertexShader,
      fragmentShader: this.fragmentShader,
    });
  }

  initInnerEarth() {
    const innerEarthGeometry = new THREE.SphereGeometry(9.5, 64, 32);
    const innerEarthMaterial = new THREE.MeshStandardMaterial({
      color: 0x00ffd1,
      transparent: true,
      opacity: 0.5,
      emissive: new THREE.Color(0x0000ff),
      emissiveIntensity: 0.5,
      metalness: 0.5
    });

    this.innerEarthMesh = new THREE.Mesh(innerEarthGeometry, innerEarthMaterial);
    this.innerEarthMesh.layers.enable(1);
    this.scene.add(this.innerEarthMesh);
  }

  addSpotlight(x, y, z, name) {
    const spotlight = new THREE.SpotLight(0xffffff);
    spotlight.position.set(x * 15, y * 15, z * 15);
    spotlight.target = this.earthMesh;
    spotlight.angle = Math.PI / 2;
    spotlight.intensity = 10;
    spotlight.penumbra = 0.5;

    this.scene.add(spotlight);
    this[name + "Spotlight"] = spotlight;
  }

  animate() {
    if (this.earthMesh) {
      this.earthMesh.rotation.y += 0.001;
    }
  }
}
