import * as THREE from "three";

export class Space {
  constructor(scene, camera) {
    this.scene = scene;
    this.camera = camera;
    this.backgroundMesh = null;
    // this.createBackground();
  }

  createBackground() {
    // Load the space texture
    const loader = new THREE.TextureLoader();
    loader.load("./space.jpg", (texture) => {
      texture.encoding = THREE.sRGBEncoding;

      // Get texture dimensions and calculate aspect ratio
      const imageAspect = texture.image.width / texture.image.height;
      let a1; let a2;
      if (imageAspect > 1) {
        // Landscape orientation
        a1 = 20; // Adjust as needed
        a2 = a1 / imageAspect;
      } else {
        // Portrait orientation
        a2 = 20; // Adjust as needed
        a1 = a2 * imageAspect;
      }

      // Create a plane geometry with the correct aspect ratio
      const backgroundGeometry = new THREE.PlaneGeometry(a1 * 5, a2 * 5);

      // Create background material
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.FrontSide,
        opacity: 1,
        transparent: true,
      });

      // Create the background mesh
      this.backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);

      // Adjust rotation and position as needed
      this.backgroundMesh.rotation.x = this.camera.rotation.x;
      this.backgroundMesh.rotation.y = this.camera.rotation.y;
      this.backgroundMesh.rotation.z = this.camera.rotation.z;
      this.backgroundMesh.position.y -= 20;

      // Add the mesh to the scene
      this.scene.add(this.backgroundMesh);
    });
  }

  animate() {
    // Rotate the background or perform other animations
    if (this.backgroundMesh) {
      this.backgroundMesh.rotation.y += 0.001;
    }
  }
}
