import * as THREE from "three";

export class EnvironmentGenerator {
  constructor(scene, camera, decreaseFog) {
    this.scene = scene;
    this.cubes = [];
    this.mouseLight = null;
    this.spotlight = null;
    this.referencePlane = null;
    this.space = 1; // Default space between cubes
    this.camera = camera; // Set the camera property
    this.fadeEffectActive = true;
    this.decreaseFog = decreaseFog;

    this.cameraDistanceFactor = 0;
    this.maxCameraDistanceFactor = 1.125; // Adjust as needed
    this.distanceFactorAcceleration = 0.0075; // Adjust as needed
  }

  createFloor() {
    const floorGeometry = new THREE.PlaneGeometry(1000, 1000, 20, 20);
    const floorMaterial = new THREE.MeshStandardMaterial({ color: 0x808080, side: THREE.DoubleSide });
    this.floor = new THREE.Mesh(floorGeometry, floorMaterial);
    this.floor.rotation.x = -Math.PI / 2;
    this.scene.add(this.floor);
  }

  createCubes(cubeSize = 3, floorSize = 100) {
    const gridSize = Math.floor(floorSize / cubeSize);

    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        // Randomly decide to place a cube or leave an empty space
        if (Math.random() > 0.65) {
          // Adjust the 0.5 threshold as needed
          const height = Math.random() * 8;
          const cubeGeometry = new THREE.BoxGeometry(cubeSize, height, cubeSize);
          // const cubeMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });
          const cubeMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            metalness: 0.5, // Adjust the metalness value
            roughness: 0.3, // Adjust the roughness value   // Adjust the roughness value for shininess
          });

          const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);

          cube.position.set(
            -floorSize / 2 + cubeSize * i + cubeSize / 2,
            height / 2,
            -floorSize / 2 + cubeSize * j + cubeSize / 2
          );

          cube.userData = {
            originalHeight: height,
            animationSpeed: Math.random() * 1 + 0.01,
            animationPhase: Math.random() * Math.PI * 2,
          };

          this.cubes.push(cube);
          this.scene.add(cube);
        }
      }
    }
  }

  updateCubesHeight(time) {
    this.cubes.forEach((cube) => {
      const minScale = 0.5 / cube.userData.originalHeight;
      const scale =
        ((Math.sin(time * cube.userData.animationSpeed + cube.userData.animationPhase) + 1) / 2) *
          (1 - minScale) +
        minScale;
      const newHeight = cube.userData.originalHeight * scale;
      cube.scale.setY(scale);
      cube.position.setY(newHeight / 2);
    });
  }
  e = false;
  updateCubes(time) {
    if (this.e) {
      return;
    }
    if (this.fadeEffectActive) {
      this.rotateCameraAroundScene(0.001);
      this.updateCubesHeight(time);
    } else {
      const status = this.decreaseFog.updateFog();
      this.updateCubesHeight(time);
      this.rotateCameraAroundScene(0.001);
      if (status) {
        this.rotateCameraAroundScene(0.01);

        let cameraAdjustmentNeeded = true;
        if (cameraAdjustmentNeeded) {
          if (
            this.cameraDistanceFactor < this.maxCameraDistanceFactor &&
            this.cameraDistanceFactor < this.maxCameraDistanceFactor - 0.45
          ) {
            this.cameraDistanceFactor += this.distanceFactorAcceleration;
          } else if (this.cameraDistanceFactor > this.maxCameraDistanceFactor - 0.45) {
            const cubesFadedEvent = new Event("cubes-faded");
            document.dispatchEvent(cubesFadedEvent);
            this.e = true;
          }
          this.camera.position.x += this.cameraDistanceFactor;
        }
      }
    }
  }

  rotateCameraAroundScene(angle) {
    // Save the current Y position of the camera
    const currentY = this.camera.position.y;

    // Calculate the new camera position after the rotation
    const newPosition = this.camera.position.clone();
    newPosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle);

    // Set the new camera position while maintaining the same height
    this.camera.position.set(newPosition.x, currentY, newPosition.z);

    // Update the camera's look-at point to the center of the scene
    this.camera.lookAt(0, 0, 0);
  }

  createMouseLight() {
    this.mouseLight = new THREE.PointLight(0x00ffd1, 10, 100);
    // this.mouseLight = new THREE.PointLight(0xff0000, 100, 100);
    this.mouseLight.position.set(0, 100, 0);
    this.scene.add(this.mouseLight);
  }

  createSpotlight() {
    this.spotlight = new THREE.SpotLight(0x01f0ff, 1);
    this.spotlight.position.set(0, 20, 0);
    this.spotlight.intensity = 50;
    this.spotlight.angle = Math.PI;
    this.spotlight.penumbra = 0.01;
    this.spotlight.decay = 2;
    this.spotlight.distance = 30;
    this.spotlight.target.position.set(0, 0, 0);
    this.scene.add(this.spotlight);
  }

  createReferencePlane() {
    const planeGeometry = new THREE.PlaneGeometry(1000, 1000);
    const planeMaterial = new THREE.MeshStandardMaterial({ visible: false });
    this.referencePlane = new THREE.Mesh(planeGeometry, planeMaterial);
    this.referencePlane.rotateX(-Math.PI / 2);
    this.scene.add(this.referencePlane);
  }

  handleMouseMove(event, raycaster, camera) {
    const x = (event.clientX / window.innerWidth) * 2 - 1;
    const y = -(event.clientY / window.innerHeight) * 2 + 1;

    // Update the raycaster
    raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

    // Calculate intersections with the reference plane
    const intersects = raycaster.intersectObject(this.referencePlane);

    if (intersects.length > 0) {
      const intersectPoint = intersects[0].point;
      const lightHeight = 10; // Set the height for the light

      // Update light position
      this.mouseLight.position.set(intersectPoint.x, lightHeight, intersectPoint.z);
    }
  }
}
