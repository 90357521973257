import * as THREE from "three";
import { EnvironmentGenerator } from "./EnvironmentGenerator";
import * as CANNON from "cannon-es";
import { Eyes } from "./Eyes";
import { Earth } from "./Earth";
import { RedFogEffect } from "./RedFogEffect";
import { Space } from "./Space";

export class SceneManager {
  smokeEffect = null;
  constructor(id, cameraPosition) {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.raycaster = new THREE.Raycaster();
    this.renderer.domElement.id = id;
    this.pointer = new THREE.Vector2();
    this.mouseMoved = false;
    this.intersection = null;
    this.clock = new THREE.Clock(); // Add this line
    this.gear1 = null;
    this.gear2 = null;
    this.scene.background = new THREE.Color(0x000000); // Using hexadecimal value

    this.world = new CANNON.World();
    this.world.gravity.set(0, -9.82, 0); // Set gravity in m/s^2

    // this.renderer.setClearColor(0xff0000); // Red color in hexadecimal
    this.smokeEffect = new RedFogEffect(this.scene);

    this.initializeInRoot(cameraPosition);
    this.animate();

  }

  createEnv() {
    this.environmentGenerator = new EnvironmentGenerator(this.scene, this.camera, this.smokeEffect);
    if (this.environmentGenerator) {
      this.environmentGenerator.createFloor();
      this.environmentGenerator.createCubes();
      this.environmentGenerator.createMouseLight();
      this.environmentGenerator.createSpotlight();
      this.environmentGenerator.createReferencePlane();

      document.addEventListener("mousemove", (event) =>
        this.environmentGenerator.handleMouseMove(event, this.raycaster, this.camera)
      );
    }
  }


  createEye() {
    this.eyes = new Eyes(this.scene, this.camera, this.renderer, this.raycaster);
    const light = new THREE.AmbientLight(0xffffff); // soft white light
    this.scene.add(light);
  }

  createEarth() {
    // this.earth = new Earth(this.scene, this.camera, this.renderer, this.raycaster);
    // const light = new THREE.AmbientLight(0xffffff); // soft white light
    // this.scene.add(light);
  }

  createSpace() {
    this.space = new Space(this.scene, this.camera, this.renderer, this.raycaster);
  }

  initializeInASpecificContainer(cameraPosition) {
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);

    this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    this.camera.lookAt(this.scene.position);

    window.addEventListener("resize", this.onWindowResize.bind(this), false);
    window.addEventListener("pointermove", this.onPointerMove.bind(this));

    const containerElement = document.getElementById(this.containerId);
    this.renderer.setSize(
      containerElement.getBoundingClientRect().width,
      containerElement.getBoundingClientRect().height
    );

    const containerDiv = document.createElement("div");

    const blurOverlay = document.createElement("div");
    this.renderer.domElement.id = "home-3d";
    containerDiv.appendChild(blurOverlay);
    containerDiv.appendChild(this.renderer.domElement);
    containerDiv.id = this.containerId;

    containerElement.replaceWith(containerDiv);
    containerElement.id = this.containerId;
  }

  initializeInRoot(cameraPosition) {
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);

    this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    this.camera.lookAt(this.scene.position);

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(this.renderer.domElement);
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    const threshold = 0.1;
    this.raycaster.params.Points.threshold = threshold;

    window.addEventListener("resize", this.onWindowResize.bind(this), false);
    window.addEventListener("pointermove", this.onPointerMove.bind(this));
  }

  updateCustomGravity(body, gravityScale) {
    const customGravity = new CANNON.Vec3(0, -9.82 * gravityScale, 0);
    const gravityForce = customGravity.scale(body.mass);
    body.applyForce(gravityForce, body.position);
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    const time = this.clock.getElapsedTime();
    this.environmentGenerator?.updateCubes(time)
    if (this.earth) {
      this.earth.animate();
    }
    this.render();
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onPointerMove(event) {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
    this.mouseMoved = true;
  }

  render() {
    this.snow?.updateSnowflakes(1);
    this.renderer.render(this.scene, this.camera);
    this.composer?.render(); // with this
  }
}
