import medTuto from '../../public/images/medtuto.png'
import myFloraboard from '../../public/images/myfloraboard.png'
import url from '../../public/images/url.svg'
import github from '../../public/images/github.png'

export default [
  {
    title: "Visions of Independence",
    subTitle: "Employment",
    startDate: "December 2022 - Present",
    learning: ["Database Architecture", "Logical Architecture", "Microsoft graph API", "Project Management"],
    topIcons: [],
    projectPicture: null,
  },
  {
    title: "MyFloraboard",
    subTitle: "My SaaS Startup",
    startDate: "July 2022 - Present",
    learning: ["OpenAI Integration", "Security Measures", "Large Database Handling"],
    topIcons: [url],
    topLink: ["https://myfloraboard.com"],
    projectPicture: myFloraboard,
  },
  {
    title: "Pillar Science",
    subTitle: "Employment",
    startDate: "January 2022 - April 2022",
    learning: ["Backend Development", "Frontend Development", "Test Building"],
    topIcons: [],
    projectPicture: null,
  },
  {
    title: "Medical Tutoring",
    subTitle: "Experimental Work",
    startDate: "June 2020",
    learning: ["Frontend UI", "Animations"],
    topLink: ["https://github.com/stars/Rygaa/lists/medtuto", "https://medicaltutoring.rygaa.com/"],
    topIcons: [github, url],
    projectPicture: medTuto,
  },
];
