import { SceneManager } from "../SceneManager";
import { initializeProjectEvents } from "./switch-projects";
import { positionTargetBelowWorkTitle } from "./position-projects";

async function loadSectionContent(sectionId) {
  try {
    const section = document.getElementById(sectionId + "-link");
    const response = await fetch(section.href);
    if (!response.ok) {
      throw new Error(`Failed to load ${section}`);
    }
    return response.text();
  } catch (error) {
    console.error(`Error loading ${section} section:`, error);
    return `<p>Error loading ${section}. Please try again later.</p>`;
  }
}

let scene = null;
async function loadAllSections() {
  const sections = ["home", "projects", "skills", "contact"];
  const contentDiv = document.getElementById("content");
  try {
    const contents = await Promise.all(sections.map(loadSectionContent));
    contentDiv.innerHTML += contents.join("");

    initializeScene();
    initializeProjectEvents();
    document.addEventListener("mousemove", function (e) {
      var lightCircle = document.getElementById("light-circle");
      lightCircle.style.left = e.pageX - 75 + "px";
      lightCircle.style.top = e.pageY - 75 + "px";
    });

    positionTargetBelowWorkTitle();

    window.addEventListener("resize", positionTargetBelowWorkTitle);
    document.documentElement.classList.add("no-scroll");

    const button = document.getElementById("view-my-work");
    const backButton = document.getElementById("back");

    backButton.addEventListener("click", () => {
      backButton.classList.remove("back-button-slide-right");
      backButton.classList.add("back-button-slide-left");
      if (window.scrollY === 0) {
        goBack2();
      } else {
        scrollToTop();
      }
    });

    const handleCubesFaded = () => {
      const home3D = document.getElementById("home-3d");
      const homeSection = document.getElementById("home");

      homeSection.style.maxHeight = "0";
      homeSection.style.minHeight = "0";
      homeSection.style.marginTop = "0";
      home3D.classList.add("scene-3d-slide-top");
      home3D.addEventListener("animationend", () => {
        document.documentElement.classList.remove("no-scroll");
        setTimeout(() => {
          homeSection.classList.remove("home-slide-left");
          home3D.classList.remove("scene-3d-slide-top");
          home3D.parentNode.removeChild(home3D);
          backButton.classList.add("back-button-slide-right");
          backButton.classList.add("back-button-slide-left");
        }, 500);

        // Remove the "cubes-faded" event listener
        document.removeEventListener("cubes-faded", handleCubesFaded);
      });
    };

    button.addEventListener("click", () => {
      const homeSection = document.getElementById("home");
      const home3D = document.getElementById("home-3d");
      console.log(homeSection)
      homeSection.classList.remove("home-slide-right");
      home3D.classList.remove("scene-3d-slide-bottom");
      homeSection.classList.add("home-slide-left");

      setTimeout(() => {
        scene.environmentGenerator.fadeEffectActive = false;
      }, 500);
      document.addEventListener("cubes-faded", handleCubesFaded);
    });
  } catch (error) {
    console.error("Error loading sections:", error);
    contentDiv.innerHTML = "<p>Error loading content. Please try again later.</p>";
  }
}

function initializeScene() {
  scene = new SceneManager("home-3d", { x: 6, y: 20, z: 20 }, "d-container", true, true);
  scene.createEnv();
}

function scrollToTop() {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });
  const backButton = document.getElementById("back");


  goBack();
  // Check if the scroll is complete
  function checkScroll() {
    if (window.scrollY === 0) {
  
      // If scrolled to the top, remove the event listener
      window.removeEventListener("scroll", checkScroll);

      // For example, you can trigger a custom event or call another function
    }
  }

  // Add a scroll event listener to check if the scroll is complete
  window.addEventListener("scroll", checkScroll);
}

function goBack() {
  initializeScene();
  const homeSection = document.getElementById("home");
  const home3D = document.getElementById("home-3d");
  scene.environmentGenerator.fadeEffectActive = true;
  document.documentElement.classList.add("no-scroll");
  homeSection.style.removeProperty("max-height");
  homeSection.style.removeProperty("min-height");
  homeSection.style.transform = "translateX(0%)";
  home3D.style.transform = "translateY(0%)";
}

function goBack2() {
  initializeScene();
  const homeSection = document.getElementById("home");
  const home3D = document.getElementById("home-3d");
  scene.environmentGenerator.fadeEffectActive = true;
  document.documentElement.classList.add("no-scroll");
  homeSection.style.removeProperty("max-height");
  homeSection.style.removeProperty("min-height");
  homeSection.classList.add("home-slide-right");
  home3D.classList.add("scene-3d-slide-bottom");
}

window.onload = () => {
  loadAllSections();
};
